
const Heading = (props) => {
    const { text } = props;

    return (
        <div className="heading">
            <div className="heading__border"></div>
            <h2 className="heading__primary">{text}</h2>
            <div className="heading__border"></div>
        </div>
    )
}

export default Heading;