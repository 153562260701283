import { useState, useEffect, useRef } from 'react';

const useNavOpen = () => {
    const [navOpen, setNavOpen] = useState(false);
    const [navActive, setNavActive] = useState('');
    
    const navOpenRef = useRef();

    const onResize = () => {
        if(document.documentElement.clientWidth > 768) {
            setNavOpen(false)
            setNavActive('')
        }
    }

    useEffect( () => {
        navOpenRef.current = navOpen;
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, [navOpen])

    useEffect( () => {
        if(navOpenRef.current !== navOpen) {
            if(navOpen) {
                setNavActive('nav__active');
                document.body.style.overflow = "hidden";
            }
            else {
                setNavActive('nav__inactive');
                document.body.style.overflow = "scroll"
            }            
        }
        navOpenRef.current = navOpen;
    }, [navOpen])

    return { navActive, navOpen, setNavActive, setNavOpen }
}

export default useNavOpen;