import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Home from './components/views/Home';
import SingleProject from './components/views/SingleProject';
import Header from "./components/layout/Header";
import SocialSide from "./components/layout/SocialSide";
import Footer from "./components/layout/Footer";

function App() {
  return (
        <div className="app">
            <Router>
                <Header />
                <SocialSide />

                <Routes>
                    <Route path="/" Component={Home} />
                    <Route path="/projects/:projectSlug" Component={SingleProject} />
                </Routes>

                <Footer />
            </Router>
        </div>
  );
}

export default App;
