import { Link } from "react-router-dom";
import ProjectList from "../ProjectsList";
import SkillsList from "../SkillsList";
import Heading from "../shared/Heading";

const Home = () => {

    const animationend = (e) => {
        e.target.classList.remove("pre__anim");
    }

    return (
        <main id="homepage">
            <section id="about-me" className="hero">
                <div className='container hero__snap'>

                    <div className='row'>
                        <div className='col-12'>

                            <div className='hero__container'>
                                <div className='hero__left'>
                                    <h1 className='hero__intro pre__anim' onAnimationEnd={animationend}>Hey there! I'm</h1>
                                    <h1 className='hero__name pre__anim' onAnimationEnd={animationend}>Kenechukwu Kelvin Obika</h1>
                                    
                                    <p className='hero__subtitle pre__anim' onAnimationEnd={animationend}>Full-stack developer with a BSc. in Computer Science, a Post-Degree Diploma in Web and Mobile Design and Development, and some experience in developing, testing, and delivering scalable web applications.</p>
                                    {/* <p className='hero__subtitle pre__anim' onAnimationEnd={animationend}>Seasoned full-stack web developer with over 5 years of experience creating highly interactive, dynamic, and performing web applications. I also bring with me a computer science degree, a team-focused mindset, an eagerness to learn new technologies, and an obsession with writing clean, readable, scalable, and maintainable code</p> */}

                                    <div className='hero__btn'>
                                        <Link to='mailto:obikakelvin.2255@gmail.com' className='btn btn__neon'>Please reach out!</Link>
                                    </div>
                                </div>
                                
                                <div className='hero__right'>
                                    <img alt='kenechukwu obika' className='hero__img' src='./images/hero-img.png' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="skills">

                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <Heading text="My Skills" />
                            
                            <SkillsList />
                        </div>
                    </div>
                </div>
            </section>

            <section id="projects">

                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <Heading text="My Projects" />
                            
                            <ProjectList />

                        </div>
                    </div>
                </div>
            </section>

            <section id="contact">

                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <Heading text="Say Hello" />
                            
                            <img alt="Contact" className="contact__photo" src="images/me.png" />

                            <p className="contact__text">Hello again! It's been a pleasure to share my experiences with you. If you are interested in working with please feel free to reach out to me! I am available to work</p>
                            
                            <div className="contact__btn">

                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}

export default Home;