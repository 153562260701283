import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import useNavSticky from '../hooks/useNavSticky';
import useNavOpen from "../hooks/useNavOpen";

import menuItems from '../../data/menuItems.json';

const Header = () => {


    const { navActive, navOpen, setNavActive, setNavOpen } = useNavOpen();
    const { sticky, stickyRef } = useNavSticky(navOpen);

    const clearAnim = () => {
        if(!navOpen) {
            setNavActive('')
        }
    }

    const displayMenuItems = () => {
        return menuItems.map((item, index) => {
            const {name, path} = item;
            return (
                <li key={index} className='nav__item'>
                    <HashLink to={path} className='nav__link' onClick={() => {setNavOpen(false)}}>
                        <span className="nav__index">0{index+1}.</span>
                        <span className="nav__text">{name}</span>
                    </HashLink>
                </li>
            )
        })
    }

    return (
        
        <nav ref={stickyRef} className={`nav ${sticky ? 'sticky' : ''}`}>
            <Link to={`/`} className="nav__logo">Obika K.</Link>

            <div
                className={`nav__content ${navActive}`}
                onAnimationEnd={clearAnim}
            >
                <ul className='nav__list'>
                    {displayMenuItems()}
                </ul>

                <div className='nav__cta'>
                    <Link 
                     target="_blank"
                        className='btn nav__btn'
                    >Resume</Link>
                </div>
            </div>

            <div className="nav__buttons">
                <input 
                    type="checkbox"
                    id="nav__menu" 
                    checked={navOpen} 
                    onChange={() => setNavOpen(!navOpen)}
                />
                
                <label htmlFor="nav__menu" className="nav__icon">
                    <div className="nav__menu"></div>
                </label>
            </div>
        </nav>
    )
}

export default Header;
