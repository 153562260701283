import Project from "./Project";
import projects from "../data/projects.json";

const ProjectList = (props) => {

    const displayProjects = () => {
        return projects.map((project, index) => {
            return (
                <Project key={index} project={project} />
            )
        })
    }
    
    return (
        <>
            {displayProjects()}
        </>
    )
}

export default ProjectList;