import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Upwork } from '../../svg/upwork.svg';
import { FaGithubSquare, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import {
    EMAIL_ADDRESS,
    GITHUB,
    UPWORK,
    LINKEDIN,
} from '../../constants';

const SocialSide = () => {
    return (
        <div className='socialside'>
            <Link to={`mailto:${EMAIL_ADDRESS}`}>
                <FaEnvelope className='socialside__icon' />
            </Link>

            <Link to={`${UPWORK}`} target='_blank'>
                <Upwork className='socialside__icon' />
            </Link>

            <Link to={`${LINKEDIN}`} target='_blank'>
                <FaLinkedin className='socialside__icon' />
            </Link>

            <Link to={`${GITHUB}`} target='_blank'>
                <FaGithubSquare className='socialside__icon' />
            </Link>
        </div>
    );
};

export default SocialSide;
