import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Heading from "../shared/Heading";
import useProject from "../hooks/useProject";

const SingleProject = () => {
    const { projectSlug } = useParams();
    const { project } = useProject({ projectSlug });
    const { 
        name,
        image,
        description, 
        overview_video,
        majorFeatures,
        thumbnail,
        link
    } = project;

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const displayMajorFeatures = () => {
        return majorFeatures?.map((feature, index) => {
            const { title, text, image } = feature;
            return (
                <div className="flex-reverse">

                    <div className="project__img--container">
                        <img alt={`feature ${index}`}  className="project__img" src={image} />
                    </div>

                    <div className="project__content">
                        <p className="project__name">{title}</p>
                        
                        <div className="project__desc--container">
                            <p className="project__desc">{text}</p>
                        </div>
                        

                    </div>
                </div>
            )
        })
        
    }

    return (
        <main id="singleproject">
            
            <section id="title">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="project__title"  data-text={name}>{name}</h1>
                            <img alt="project" className="project__image" src={`${image}`} />

                            {link &&
                                <div className="project__btn">
                                    <Link 
                                        className="btn btn__primary" 
                                        to={link} 
                                        target="_blank"
                                    >Visit Website</Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section id="overview">

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Heading text="Overview" />

                            <p className="project__overview">{description}</p>

                            {overview_video && 
                                <video className="project__video" poster={thumbnail} controls>
                                    <source src={overview_video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            }
                        </div>
                    </div>
                </div>
            
            </section>

            <section id="features">

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Heading text="Some Features" />

                            {displayMajorFeatures()}
                        </div>
                    </div>
                </div>
            
            </section>

        </main>
    )
}

export default SingleProject;