import { Link } from "react-router-dom";

const Project = (props) => {

    const { project } = props;
    const { name, isLandingPage, link, description, slug, featuredImages, tech, responsibilities } = project;

    const displayImages = () => {

        if(featuredImages?.length === 0) {
            return (<></>)
        }

        return featuredImages?.map((image, index) => {
            const href = isLandingPage !== undefined ? `/projects/${slug}` : link;
            const target = isLandingPage !== undefined ? '_parent' : '_blank';
            return (
                <Link key={index} to={href} target={target}>
                    <img 
                        className={`composition__photo composition__photo--p${index+1}`} 
                        src={image}
                        alt={image}
                    />
                </Link>
            )
        })
    }

    const displayTech = () => {
        
        if(tech?.length === 0) {
            return (<></>)
        }

        return tech?.map((tech, index) => {
            return (
                <li key={index} className="tech__item">
                    <p className="tech__p">{tech}</p>
                </li>
            )
        })
    }

    const displayResponsibilities = () => {
        
        if(!responsibilities || responsibilities.length === 0) {
            return (<></>)
        }

        return responsibilities.map((item, index) => {
            return (<li key={index}>{item}</li>)
        })
    }

    return (
        <div className="project flex-reverse">

            <div className="composition">
                {displayImages()}
            </div>

            <div className="project__content">
                {/* <Link to={`/projects/${slug}`} className="project__name">{name}</Link> */}
                <Link to={link} className="project__name" target="_blank">{name}</Link>
                
                <div className="project__desc--container">
                    <p className="project__desc">{description}</p>
                    <p className="project__desc"> These are some of the things I worked on:</p>
                    <ul className="project__list">
                        {displayResponsibilities()}
                    </ul>
                </div>
                
                <ul className="tech__list">
                    {displayTech()}
                </ul>

                {/* <Link 
                    to={`/projects/${name}`} 
                    className='project__link'
                >Learn more</Link> */}
                

            </div>
        </div>
    )
}

export default Project;