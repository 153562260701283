import { useState, useEffect, useRef, useCallback } from 'react';

const useNavSticky = (navOpen) => {
    const lastScrollTopRef = useRef(0);
    const stickyRef = useRef(null);

    const [sticky, setSticky] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        if (!stickyRef.current) {
            return;
        }

        setOffset(stickyRef.current.offsetTop);

    }, []);

    const handleScroll = useCallback(() => {
        if (!stickyRef.current) {
          return;
        }

        const windowScroll = window.scrollY

        if(!navOpen) {
            if(windowScroll > offset) {
                stickyRef.current.style.transform = "translateY(-10rem)";
            }
            if (windowScroll < lastScrollTopRef.current){
                stickyRef.current.style.transform = "translateY(0)";
            }
        }
  
        setSticky(windowScroll > offset);
        lastScrollTopRef.current = windowScroll <= 0 ? 0 : windowScroll;
    }, [navOpen, offset])

    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll)
        };
    }, [handleScroll, stickyRef, offset]);
      
      
    return { stickyRef, sticky };

}

export default useNavSticky;