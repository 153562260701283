import { Link } from 'react-router-dom';
import { ReactComponent as Upwork } from '../../svg/upwork.svg';
import { FaGithubSquare, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import {
    EMAIL_ADDRESS,
    GITHUB,
    UPWORK,
    LINKEDIN,
} from '../../constants';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer__socials'>
                <Link to={`mailto:${EMAIL_ADDRESS}`}>
                    <FaEnvelope className='footer__icon' />
                </Link>

                <Link to={`${UPWORK}`} target='_blank'>
                    <Upwork className='footer__icon' />
                </Link>

                <Link to={`${LINKEDIN}`} target='_blank'>
                    <FaLinkedin className='footer__icon' />
                </Link>

                <Link to={`${GITHUB}`} target='_blank'>
                    <FaGithubSquare className='footer__icon' />
                </Link>
            </div>

            <p className='footer__caption'>
                &copy; All rights reserved. Obika K.{' '}
            </p>
        </div>
    );
};

export default Footer;
