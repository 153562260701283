import { 
    FaHtml5,
    FaCss3,
    FaSass,
    FaJs, 
    FaReact,
    FaNodeJs,
    FaLaravel,
    FaPhp,
    FaDocker,
} from "react-icons/fa6";
import { BiLogoKubernetes, BiLogoMongodb } from "react-icons/bi";
import { DiMysql } from "react-icons/di";

const SkillsList = () => {

    return (
        <div className="skills">
            <div className="skills__item">
                <FaHtml5 className="skills__icon skills__react" />
                <p className="skills__text">HTML</p>
            </div>

            <div className="skills__item">
                <FaCss3 className="skills__icon" />
                <p className="skills__text">CSS</p>
            </div>

            <div className="skills__item">
                <FaSass className="skills__icon" />
                <p className="skills__text">Sass</p>
            </div>

            <div className="skills__item">
                <FaJs className="skills__icon" />
                <p className="skills__text">Javascript</p>
            </div>

            <div className="skills__item">
                <FaReact className="skills__icon skills__react" />
                <p className="skills__text">React</p>
            </div>

            <div className="skills__item">
                <FaNodeJs className="skills__icon skills__react" />
                <p className="skills__text">Node Js</p>
            </div>

            <div className="skills__item">
                <FaPhp className="skills__icon skills__react" />
                <p className="skills__text">PHP</p>
            </div>

            <div className="skills__item">
                <FaLaravel className="skills__icon skills__react" />
                <p className="skills__text">Laravel</p>
            </div>

            <div className="skills__item">
                <DiMysql className="skills__icon skills__react" />
                <p className="skills__text">MySQL</p>
            </div>

            <div className="skills__item">
                <BiLogoMongodb className="skills__icon skills__react" />
                <p className="skills__text">MongoDB</p>
            </div>

            <div className="skills__item">
                <FaDocker className="skills__icon skills__react" />
                <p className="skills__text">Docker</p>
            </div>

            <div className="skills__item">
                <BiLogoKubernetes className="skills__icon skills__react" />
                <p className="skills__text">Kubernetes</p>
            </div>

        </div>
    )
    
}

export default SkillsList;