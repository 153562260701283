import { useState, useEffect } from "react";
import projects from "../../data/projects.json";


const useProject = (props) => {
    const { projectSlug } = props;
    const [project, setProject] = useState('');

    useEffect(() => {
        const projectCurent = projects.find(project => project.slug === projectSlug);
        setProject(projectCurent);
    }, [projectSlug])

    return { project };
}

export default useProject;